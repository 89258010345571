import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from 'gatsby-image-background-slider'

import "../styles/global.css"

export default function PhotoAlbum() {

  return (
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
              nodes {
                relativePath
                childImageSharp {
                  fluid (maxWidth: 4000, quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        initDelay={3} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={4} // transition duration between images
        duration={10} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={["image1.jpg", "image7.jpg", "image15.jpg", 'image9.jpg', 'image6.jpg', 'image23.jpg']}
        // pass down standard element props

      >
        <div className='caption'>Maree in his natural habitat at Kruger.</div>
        <div className='caption'>Maree and his daughters (Laryn, Rouvé and Danelle)</div>
        <div className='caption'>Maree and Anne-marie at their wedding</div>
        <div className='caption'>Maree with Rouvé at her wedding</div>
        <div className='caption'>Maree with his eldest daughter Laryn</div>
        <div className='caption'>Maree with his youngest daughter Danelle</div>
      </BackgroundSlider>
  )
}