import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PhotoAlbum from '../components/PhotoAlbum'
import Sunset from '../../static/dad-photos/capetown-sunset.jpg'

import DadSport from '../../static/dad-photos/image22.jpg'
import DadGolf from '../../static/dad-photos/image20.jpg'
// import Tennis from '../../static/dad-photos/image19.jpg'

import FamilyWedding from '../../static/dad-photos/image25.jpg'
// import Fam from '../../static/dad-photos/image2.jpg'
import FamilyHalloween from '../../static/dad-photos/image14.jpg'
import MareeAnnemarie from '../../static/dad-photos/image3.jpg'
import DadDesk from '../../static/dad-photos/dads-desk.jpg'
// import DadLaryn from '../../static/dad-photos/image10.jpg'
// import DadRouve from '../../static/dad-photos/image4.jpg'

import DadKruger from '../../static/dad-photos/image12.jpg'
import DadTree1 from '../../static/dad-photos/dad-tree.jpg'
import DadTree from '../../static/dad-photos/treehugger.jpg'
import DadCamera from '../../static/dad-photos/image13.jpg'
import DadDan from '../../static/dad-photos/image24.jpg'

import "../styles/global.css"

export default function Home() {
  return (
    <Layout>
      <div className='home-section' style={{ marginTop: '2rem' }}>
        <div className='background-slider-container'>
        <PhotoAlbum />
        </div>
        <div className='home-tile'>
          <h1>1955 - 2020</h1>
          <p>This site is a small collection of memories honouring Maree's life. We hope it goes some way towards illustrating a fraction of the love, wisdom and kindness he brought into our lives.</p>
          <p>Please see the <Link to="/photos/">Photography</Link> section to browse his beautiful collection of wildlife photos taken at the Kruger National Park.</p>
          <p>If you have any photos or memories you'd like to share, please <a href="mailto:dani.wessels@gmail.com">contact us</a>.</p>
        </div>
      </div>

      <div style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `linear-gradient(180deg, rgb(218 240 255 / 100%), rgb(202 226 231 / 0%), rgb(202 226 231 / 0%)), url(${Sunset})`, minHeight: '25rem', width: '100vw' }}>

      <div className='home-section' style={{ height: '35rem', position: 'relative', color: '#ffffff', alignItems: 'start' }}>
        <div className='home-tile' style={{ height: '70%', width: '20rem', position: 'relative', flexGrow: '0' }}>
          <h2 style={{padding: '1rem 0'}}>Playlist</h2>
          <p></p>
          <iframe src="https://open.spotify.com/embed/playlist/1PkEQYzsLhQG3KyzqkCuJh" width="100%" height="100%" frameBorder="0" allowtransparency="true" allow="encrypted-media" title='spotify-playlist'></iframe>
        </div>
      </div>
      {/*<div class="caption" style={{ marginRight: '10px', marginTop: '-5px'}} >Photo taken by Maree.</div>*/}
      </div>

      <div className='home-section' style={{ marginBottom: '1rem', marginTop: '5rem', padding: '0 2rem', justifyContent: 'end' }}>
          <h2>Wessels family</h2>
      </div>

       <div className='home-section' style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div className='tile'>
          <img src={FamilyWedding} />
          <div class="caption" >Rouvé & Pieter's wedding</div>
        </div>
        <div className='tile'>
          <img src={MareeAnnemarie} />
          <div class="caption" >Maree & Anne-marie</div>
        </div>
        <div className='tile'>
          <img src={DadDesk} />
          <div class="caption" >The view from Maree's desk at St Anne's</div>
        </div>
        <div className='tile'>
          <img src={FamilyHalloween}/>
          <div class="caption" >Halloween hippies</div>
        </div>
      </div>

<div style={{ padding: '0.1rem 0 3rem', backgroundColor: '#22222f', width: '100vw' }}>

      <div className='home-section' style={{ marginBottom: '1rem', marginTop: '5rem', padding: '0 2rem' }}>
        <h2 style={{ color: '#b4b5b7' }}>Maree & nature</h2>
      </div>

       <div className='home-section' style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'  }}>
       <div className='tile'>
          <img src={DadTree1} />
          <div class="caption" >Befriending a tree</div>
        </div>
       <div className='tile'>
          <img src={DadCamera} />
          <div class="caption" >Maree honing his art</div>
        </div>
        <div className='tile'>
          <img src={DadTree}  />
          <div class="caption" >Hugging trees (again)</div>
        </div>
        <div className='tile'>
          <img src={DadDan} />
          <div class="caption" >Taking photos at Kruger</div>
        </div>
        <div className='tile'>
          <img src={DadKruger}  />
          <div class="caption" >Maree enjoying the view</div>
        </div>
      </div>

</div>

      <div className='home-section' style={{ marginBottom: '1rem', marginTop: '5rem', padding: '0 2rem', justifyContent: 'end' }}>
        <h2>Sport family</h2>
      </div>

      <div className='home-section' style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '5rem' }}>
          {/*<div className='tile'>
            <img src={Tennis} alt="Maree with the sports department" />
          <div class="caption" >St Anne's tennis department</div>
        </div>*/}
        <div className='tile'>
          <img src={DadSport} alt="Maree with the sports department" />
          <div class="caption" >St Anne's sports department</div>
        </div>
        <div className='tile'>
          <img src={DadGolf} alt='The Silver Lakes golf crew' />
          <div class="caption" >Silver Lakes golf club 'Dream Team'</div>
        </div>
      </div>
    </Layout>
  )
}